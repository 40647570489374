























import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { useUiMode, useIsMounted } from '~/composables';
import type { ProductCardSize } from '~/constants/productCardSize';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';

const PRODUCT_SLIDER_SIZE = {
  MINI: 'mini',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export default defineComponent({
  name: 'ProductSlider',
  components: {
    SimpleCarousel
  },
  props: {
    sliderItems: {
      type: Array,
      default: () => []
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 4
    },
    size: {
      type: String as PropType<ProductCardSize>,
      default: PRODUCT_CARD_SIZE.RESPONSIVE,
      validator: (value: ProductCardSize) => {
        return Object.values(PRODUCT_CARD_SIZE).includes(value);
      }
    }
  },
  setup(props) {
    const { isMobile } = useUiMode();
    const { isMounted } = useIsMounted();

    const wrapperClasses = computed(() => `mx-auto${props.isFullWidth ? '' : ' lg:max-w-relatedProducts'}`);
    const productCardSize = computed(() => {
      if (props.size === PRODUCT_CARD_SIZE.MINI) {
        return PRODUCT_SLIDER_SIZE.MINI;
      }
      if (props.size === PRODUCT_CARD_SIZE.MEDIUM || isMobile.value || !isMounted.value) {
        return PRODUCT_SLIDER_SIZE.MEDIUM;
      }
      return PRODUCT_SLIDER_SIZE.LARGE;
    });

    const productCardWidths = {
      [PRODUCT_SLIDER_SIZE.MINI]: 150,
      [PRODUCT_SLIDER_SIZE.MEDIUM]: 229,
      [PRODUCT_SLIDER_SIZE.LARGE]: 337
    };
    const productCardWidth = computed(() => productCardWidths[productCardSize.value]);

    return {
      LinkType,
      wrapperClasses,
      PRODUCT_CARD_SIZE,
      productCardWidth
    };
  }
});

